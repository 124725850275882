import * as React from "react";
import { CoinomicsQuery_CryptoCategory } from "../../../../graphql-types";
import BackAndSearch from "../../shared/back-and-search/back-and-search";
import CoinListTable from "../../shared/coin-list-table/coin-list-table";
import Container from "../../ui-library/container/container";
import PageHeading from "../../ui-library/page-heading/page-heading";

interface CategoryProps {
  category: CoinomicsQuery_CryptoCategory;
}

const Category: React.FC<CategoryProps> = ({ category }) => {
  return (
    <>
      <BackAndSearch />

      <Container>
        <PageHeading
          heading={`Top ${category.name} Coins by Market Capitalization`}
        />
      </Container>

      <Container fullWidthOnMobile={true}>
        <CoinListTable
          staticData={category.coins.map((c) => ({ ...c, image: c.imageUrl }))}
          ids={category.coins.map((c) => c.id)}
        />
      </Container>
    </>
  );
};

export default Category;
