import { PageProps } from "gatsby";
import * as React from "react";
import { CoinomicsQuery_CryptoCategory } from "../../graphql-types";
import Layout from "../components/layout/layout";
import { NavTab } from "../components/layout/navigation/navigation";
import Category from "../components/pages/category/category";
import SEO from "../components/seo";

type CategoryPageProps = PageProps<
  null,
  { category: CoinomicsQuery_CryptoCategory }
>;

const CategoryPage: React.FC<CategoryPageProps> = ({
  pageContext: { category },
}) => {
  const categoryName = category.name;
  return (
    <Layout activeTab={NavTab.Categories}>
      <SEO
        title={`${categoryName} crypto category`}
        description={`${categoryName} coins and tokens. Explore real-time market insights on cryptocurrency prices, market cap, ranking and other details`}
      />

      <Category category={category} />
    </Layout>
  );
};

export default CategoryPage;
