import { CSSObject } from "@emotion/react";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";

const root: CSSObject = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: grid(-1),
  marginBottom: grid(2),

  [Device.DesktopTablet]: {
    marginTop: grid(-1.5),
    marginBottom: grid(2.5),
  },

  "> div:last-of-type": {
    [Device.DesktopTablet]: {
      width: grid(30),
    },
  },
};

const back: CSSObject = {
  display: "flex",
  alignItems: "center",
};

const styles = {
  root,
  back,
};

export default styles;
