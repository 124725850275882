import { navigate } from "gatsby";
import * as React from "react";
import { EventCategory } from "../../../utils/analytics";
import Search from "../../search/search";
import Actionable from "../../ui-library/actionable/actionable";
import Container from "../../ui-library/container/container";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";
import { grid } from "../../ui-library/design-tokens/grid";
import { ChevronIcon } from "../../ui-library/icons/chevron-icon";
import Text from "../../ui-library/text/text";
import styles from "./back-and-search.styles";

interface BackAndSearchProps {
  backUrl?: string;
}

const BackAndSearch: React.FC<BackAndSearchProps> = ({ backUrl }) => {
  const goBack = () => {
    if (history && history.length > 1) {
      history.back();
    } else {
      navigate(backUrl || "/");
    }
  };

  return (
    <Container css={styles.root}>
      <Actionable
        role="link"
        ariaLabel="Back"
        onClick={goBack}
        css={styles.back}
        analytics={{
          category: EventCategory.Site_Navigate,
          action: "Click - Back",
          label: "Back",
        }}
      >
        <ChevronIcon color={Colors.MidGrey} direction="left" size={14} />
        <Text
          textColor={Colors.MidGrey}
          weight={Fonts.Weight.SemiBold}
          margin={{ left: grid(1) }}
        >
          Back
        </Text>
      </Actionable>

      <Search />
    </Container>
  );
};

export default BackAndSearch;
